import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Typography } from '@material-ui/core';
import { graphql } from 'gatsby';

import useStyles from '../pagesStyles/adhesionStyles';
import { addProtocol } from '../utils/format';

import Layout from '../components/layout';
import Title from '../components/Title/index';
import CustomButton from '../components/CustomButton/customButton';
import Subtitle from '../components/Subtitle/index';
import Icon from '../components/Icons/Icon/index';

import ShareIcon from '@material-ui/icons/Share';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { faBalanceScale, faHandshake, faGem } from '@fortawesome/free-solid-svg-icons';

const AdhesionPage = ({data}) => {

    const classes = useStyles();

    const md = data.markdownRemark.frontmatter;

    return (
        <Layout>

          <Helmet>
            <html lang="fr" />
            <title>{md.SEOtitle}</title>
            <meta name="description" content={md.SEOdescription} />
          </Helmet>

            <Title title={md.title} />

            <Box className={classes.introductionContainer}>

            <Typography component="p" variant="subtitle1" >
              <span className={classes.bold}>Pour vous aider</span> concrètement dans votre pratique et <span className={classes.bold}>vous soutenir</span> au quotidien dans la <span className={classes.bold}>gestion de votre entreprise libérale</span> : des services numériques novateurs, faciles d’accès et sécures en matière de cotation des actes, de documentation et d’outils téléchargeables indispensables aux bonnes pratiques pour éviter des recherches laborieuses. 
            </Typography>
            <Typography component="p" variant="subtitle1">
              Pour en finir avec le casse-tête pour coter les actes et la peur de mal coter, une solution <span className={classes.bold}>pour sécuriser vos cotations</span> en toute autonomie sans attendre la réponse d’une hotline grâce à son calculateur automatique. 
            </Typography>
            <Typography component="p" variant="subtitle1">
              Rien ne sera plus facile que de <span className={classes.bold}>suivre des formations</span> en temps réel ou en différé sur des thèmes variés qui intéressent toutes les IDEL : formations métiers, NGAP, gestion de cabinet, assurances, comptabilité. Le SAIIL vous propose régulièrement des temps d’échanges en visioconférence, il vous suffira de vous connecter via son site pour retrouver nos experts et poser toutes vos questions … 
            </Typography>
            <Typography component="p" variant="subtitle1">
              Pour être au fait de l’actualité, le SAIIL assure pour vous <span className={classes.bold}>une veille info santé</span> et vous la relaie aussi vite !
            </Typography>
            <Typography component="p" variant="subtitle1">
              Le <span className={classes.bold}>conseil juridique</span> est accessible directement via le site : posez votre question juridique et nos avocats partenaires spécialisés dans le domaine de l’IDEL vous répondront rapidement ! 
            </Typography>
            <Typography component="p" variant="subtitle1">
              N’hésitez pas à devenir adhérent pour <span className={classes.boldOrange}>140 € / an</span> (déductible de vos frais professionnels) et ainsi vous bénéficierez de <span className={classes.boldGreen}>tous les services digitaux</span> créés pour vous par des IDEL engagés à vos côtés !
            </Typography>

            </Box>


            <Box className={classes.fourBoxes}>
                <Box className={classes.rowContainerGreen}>
                
                    <div className={classes.iconContainerLeft}>
                        <div className={classes.fontawesomeSmall}>
                            <Icon icon={faHandshake} variant='white' size='4x' />   
                        </div>
                        <div className={classes.fontawesomeBig}>
                            <Icon icon={faHandshake} variant='white' size='6x' />
                        </div>
                            <Typography gutterBottom component="h2" className={classes.colorTextSecondary}>
                                {md.reason1}
                            </Typography>
                    </div>

                    <div className={classes.iconContainerRight}>
                        <div className={classes.fontawesomeSmall}>
                            <Icon icon={faGem} variant='white' size='4x' />   
                        </div>
                        <div className={classes.fontawesomeBig}>
                            <Icon icon={faGem} variant='white' size='6x' />
                        </div>
                            <Typography gutterBottom component="h2" className={classes.colorTextSecondary}>
                                {md.reason2}
                            </Typography>
                    </div>
                </Box>

                <Box className={classes.rowContainerGreen}>
                    <div className={classes.iconContainerLeft}>
                        <ShareIcon fontSize='large' className={classes.materialIcon} />
                        <Typography gutterBottom component="h2" className={classes.colorTextSecondary}>
                            {md.reason3}
                        </Typography>
                    </div>

                    <div className={classes.iconContainerRight}>
                        <div className={classes.fontawesomeSmall}>
                            <Icon icon={faBalanceScale} variant='white' size='4x' />   
                        </div>
                        <div className={classes.fontawesomeBig}>
                            <Icon icon={faBalanceScale} variant='white' size='6x' />
                        </div>
                            <Typography gutterBottom component="h2" className={classes.colorTextSecondary}>
                                {md.reason4}
                            </Typography>
                    </div>
                </Box>
            </Box>

            <Box className={classes.rowContainerOrange}>
                <div className={classes.iconContainerOrange}>
                    <InsertDriveFileIcon fontSize='large' className={classes.materialIcon} />
                    <Typography gutterBottom component="h2" className={classes.colorTextSecondary}>
                        {md.reason5}
                    </Typography>
                </div>
            </Box>

            <Box className={classes.button}>
                <CustomButton 
                text="J'adhère"
                link={`${addProtocol(process.env.GATSBY_ADHERENT_URL, "https")}`}
                isOutsideLink='true'
                />
            </Box>

        </Layout>
    )
}

export default AdhesionPage

export const query = graphql`
  query {
    markdownRemark(fileAbsolutePath: {regex: "/adhesion.md/"}) {
        frontmatter {
          SEOtitle
          SEOdescription
          title 
          reason1
          reason2
          reason3
          reason4
          reason5
        }
      }
  }
`