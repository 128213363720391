import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(5)
    }
  },
  typography: {
    fontSize: 23,
    '@media (max-width:800px)': {
      fontSize: 16,
    },
  }
}));
