import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import styles from './styles';

const Subtitle = ({ text }) => {
  const classes = styles();
  const formattedText = text.split(`\n`).map((p, key) => (
    <Box my={2} key={key}>
      <Typography component="p" className={classes.typography}>{p.replace(/\n/g, '<br/>')}</Typography>
    </Box>
  ))
  return <Box className={classes.wrapper}>
    {formattedText}
  </Box>
};

export default Subtitle

Subtitle.propTypes = {
  text: PropTypes.string.isRequired,
};
