import makeStyles from '@material-ui/core/styles/makeStyles'

export default makeStyles(theme => ({
    introductionContainer: {
      margin: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        margin: theme.spacing(5)
      }
    },
    bold: {
      fontWeight: 'bold'
    },
    boldOrange: {
      fontWeight: 'bold',
      color: '#C45824'
    },
    boldGreen: {
      fontWeight: 'bold',
      color: '#0E5C45'
    },
    fourBoxes: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingTop: '1%',
        '@media (max-width:550px)': {
            flexDirection: 'column'
        },
    },

    colorTextSecondary: {
        color: '#fff',
        fontWeight: 700,
        fontSize: '1.5rem',
        width: '60%',
        '@media (max-width:1150px)': {
            fontSize: '1rem'
        },
        '@media (max-width:400px)': {
            fontSize: '0.8rem'
        }
    },

    rowContainerGreen: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#0E5C45',
        marginRight: '20%',
        marginLeft: '20%',
        border: 'solid 1px #707070',
        '@media (max-width:760px)': {
            border: 'none',
            borderBottom: 'solid 1px #707070', 
            flexFlow: 'column'
        },
        '@media (max-width:500px)': {
            marginRight: '5%',
            marginLeft: '5%'
        }
    },

    rowContainerOrange: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#C45824',
        marginRight: '20%',
        marginLeft: '20%',
        '@media (max-width:760px)': {
            flexFlow: 'column'
        },
        '@media (max-width:500px)': {
            marginRight: '5%',
            marginLeft: '5%'
        }
    },

    iconContainerLeft: {
        height: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        borderRight: 'solid 0.5px #707070',
        width: '50%',
        '@media (max-width:760px)': {
            borderRight: 'none',
            width: '100%',
            borderBottom: 'solid 1px #707070', 

        }
   },

   iconContainerRight: {
    height: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    borderLeft: 'solid 0.5px #707070',
    width: '50%',
    '@media (max-width:760px)': {
        border: 'none',
        width: '100%'
    }
},

   iconContainerOrange: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: 150,
    '@media (max-width:550px)': {
        margin: '3%'
    }
},

   fontawesomeSmall: {
       display: 'none',
    '@media (max-width: 549px)': {
        display: 'inline-block'
    }
   },

   fontawesomeBig: {
    '@media (max-width:550px)': {
        display: 'none'
    },
   },

   materialIcon: {
       color: '#ffffff',
       fontSize: '6rem !important',
       '@media (max-width:550px)': {
        fontSize: '4rem !important',
    },
   },

   button: {
       display: 'flex',
       justifyContent: 'center',
       paddingTop: '2%',    
       '@media (max-width: 500px)': {
        paddingTop: '3%'
    }
   }
    
  }));
