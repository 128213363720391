import React from 'react'
import PropTypes from 'prop-types';
import { Typography } from "@material-ui/core"
import useStyles from './styles';

const Title = ({ title }) => {
  const classes = useStyles();
  return <Typography variant="h1" className={classes.typo}>{title}</Typography>
}

export default Title

Title.propTypes = {
  title: PropTypes.string,
};
